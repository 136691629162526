<template>
    <v-card color="#F6F9FC" flat class="rounded-0">
        <v-container>
            <v-row>
      
                <v-col cols="12" lg="3">
                    <v-card height="50" class="d-flex justify-space-between align-center" flat color="transparent">
                        <p class="mb-0"><v-icon color="#333333">mdi-filter-outline</v-icon><span>{{ $t('筛选') }}</span>
                        </p>
                        <v-icon color="#333333" @click="closeTag" v-show="getparam.themes_tag_id||getparam.start_price">mdi-close</v-icon>
                    </v-card>
                    <v-card flat>
                        <v-card-title class="pb-0">
                            {{ $t('所有类别') }} 
                        </v-card-title>
                       
                        <v-card-text class="px-0">
                            <v-list dense rounded>
                                <v-list-item-group v-model="selectedItem" color="primary">
                                    <v-list-item v-for="(item, i) in catelist" :key="i" @click="changeTag(item)">

                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.name"></v-list-item-title>

                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <span>{{ item.count }}</span>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-card-text>
                    </v-card>
                    <!-- <v-card flat class="mt-4">
                        <v-card-title>
                            {{ $t('调色板') }}
                        </v-card-title>
                        <v-card-text class=" d-flex justify-space-between">
                            <v-card flat width="16" height="16" style="border-radius: 50%;" :color="citem"
                                v-for="citem in colorlist"></v-card>
                        </v-card-text>
                    </v-card> -->
                    <v-card flat class="mt-4">
                        <v-card-title>
                            {{ $t('价格') }}
                        </v-card-title>
                        <v-card-text class=" d-flex justify-space-between align-center" v-if="shopInfo">
                            <v-card height="40" flat>
                                <v-text-field solo flat dense :prefix="shopInfo.symbol" background-color="#f7f7f7" v-model="getparam.start_price"></v-text-field>
                            </v-card>
                            <v-icon color="#333333" size="20" class="px-2">mdi-minus</v-icon>

                            <v-card height="40" flat>
                                <v-text-field solo flat dense :prefix="shopInfo.symbol" background-color="#f7f7f7" v-model="getparam.end_price"></v-text-field>
                            </v-card>
                            <v-btn depressed height="40" class="ml-2" @click="getlist()">
                                <v-icon>mdi-check</v-icon>
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="9">
                    <v-card height="50" flat color="transparent" class="d-flex justify-space-between align-center">
                        <p class="mb-0 text-body-1 font-weight-medium">{{ selectTag }}</p>
                    </v-card>

                    <v-row class="ma-0" v-if="mobanlist.length>0">
                        <v-col cols="6" md="4" sm="4" class="" v-for="n in mobanlist" :key="n.themes_id">
                            <v-card flat class="rounded-lg" :href="'/template-detail?themes_id=' + n.themes_id">

                                <v-img :src="n.cover_img" alt="">

                                </v-img>

                                <p class="font18  my-2 px-2">{{ n.name }}</p>
                                <p class="text--secondary text-body-2 px-2 text-no-wrap text-truncate">
                                    {{ n.des }}
                                </p>
                                <div class="d-flex justify-space-between align-center px-2 pb-4">
                                    <span class="text-h6 font-weight-bold"> {{ n.symbol }}{{ n.price }}</span>
                                    <v-btn color="#F2F2F2" class="black--text" rounded depressed small>{{ $t('查看详情')
                                        }}</v-btn>
                                </div>
                            </v-card>
                        </v-col>

                    </v-row>
                    <v-card v-else flat class="text-center">
                           <img src="@/assets/images/template/no-buyed.png" alt="">
                           <p class="grey--text">{{ $t('没有结果') }}</p>
                    </v-card>
                    <v-pagination v-model="getparam.current_page" :length="pageTotal"  total-visible="5" @input="getlist()" v-if="pageTotal>0"></v-pagination>
                </v-col>
            </v-row>
          
        </v-container>

    </v-card>
</template>
<script>
export default {
    data() {
        return {
            catelist: [
                { text: 'Real-Time', num: '12' },
                { text: 'Real-Time', num: '12' },
                { text: 'Real-Time', num: '12' },
                { text: 'Real-Time', num: '12' },
                { text: 'Real-Time', num: '12' },
                { text: 'Real-Time', num: '12' },

            ],
            selectedItem: '',
            selectTag:'',
            colorlist: ['#F65199', '#B22FFB', '#5855FA', '#15BBE7', '#26D1C0', '#9EDA18', '#68C276', '#FED01A', '#FDA418', '#FB7319', '#F4491B'],
            getparam: {
                current_page: 1,
                page_number: 10,
                start_price:'',
                end_price:'',
                keyword:''
            },
            pageTotal: 1,
            mobanlist: [],
            shopInfo:null
        }
    },
    mounted() {
        this.getparam.keyword=this.$route.query.keyword;
        this.getlist();
        this.gettaglist();
        this.shopInfo=JSON.parse(localStorage.getItem('DROPSHOPINFO'))
    },
    methods: {
        getlist() {
            this.$api.template.list(this.getparam).then(res => {
                if (res.status) {
                    this.mobanlist = res.data.records;
                    this.pageTotal = Math.ceil(
                        parseInt(res.data.total) / this.getparam.page_number
                    );
                }
            });
        },
        gettaglist() {
            this.$api.template.tag_list().then(res => {
                if (res.status) {
                    this.catelist = res.data;
                    
                }
            });
        },
        changeTag(item){
            this.selectTag=item.name;
            this.getparam['themes_tag_id']=item.themes_tag_id;
            this.getlist();
        },
        closeTag(){
            this.selectTag='';
            this.selectedItem='';
            this.getparam.start_price='';
            this.getparam.end_price='';
            delete this.getparam.themes_tag_id;
            this.getlist();
        }
    }
}
</script>
<style>
.filter-price-input {
    background-color: #F7F7F7;
}
</style>